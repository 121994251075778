<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="wrap">
      <div class="h1">
        2024年国家级放射影像专业质控中心亚专业组组长第一次会议<br />
        成功召开
      </div>
      <section>
        <div class="div_p">
          2024年5月11日下午2点，国家放射影像专业质量控制中心在线上召开2024年亚专业组组长第一次会议。国家级放射影像专业质控中心副主任委员、北京协和医院薛华丹教授担任主持。首先由国家放射影像专业质控中心主任委员、北京协和医院金征宇教授布置亚专业组任务。金征宇教授讲话提到，2024年国家级质控中心的工作计划包括摸清学科现状、规范组织框架、夯实指标体系、推进中心高质量运行。落实“八大项23小项”，全面做实做细“六个一”任务。2024年1到6月，重点推荐，完善组织构架、建立规章制度的工作，包括建立亚专业指标专家组工作及工作制度，完善哨点医院工作及工作要求，完善国-省-市-县四级联动工作及工作制度，继续“织网”工作。
        </div>
        <div class="div_p">
          接下来金教授介绍了2024年亚专业组工作内容，目前已经组建9个亚专业指标专家工作组，未来需建立各亚专业组微信工作群，包括亚专业组组长、副组长/秘书以及组员。放射影像专业医疗质控指标已确立，等待卫健委正式文件发布。希望各亚专业组从实际出发，制定亚专业组危急值，细化危急值具体定义，录制国家质控网站视频讲座，在全国进行重点宣讲培训，编写危急值图集。编订行业规范/共识手册，作为培训大纲，使得质控更加标准化及规范化。
        </div>
        <div>
          <img src="@/assets/HY20240622/1.png" alt="">
        </div>
        <div class="div_tit">
          国家级放射影像专业质控中心主任金征宇教授布置亚专业组工作任务
        </div>
        <div class="div_p">
          接下来由亚专业组组长发言并进行讨论。心胸组组长中国人民解放军海军军医大学第二附属医院刘士远教授、神经组组长北京大学人民医院洪楠教授、头颈组组长同济大学附属同济医院王培军教授、X线组组长吉林大学第一医院张惠茅教授、急诊组组长中国人民解放军东部战区总医院卢光明教授、对比剂组组长广东省人民医院梁长虹教授、四川大学华西医院李真林教授代表CT组组长宋彬教授、以及北京医院张晨教授代表MR组组长陈敏教授、上海交通大学医学院附属瑞金医院宋琦教授代表消化组组长严福华教授均参与讨论并发言，表示未来将根据卫健委要求及国家质控中心布置的工作内容，开展亚专业组工作，完成好质控指标培训、编订行业规范/共识手册等工作。
        </div>
        <div class="div_p">
          最后，国家放射影像专业质控中心主任委员金征宇教授总结，除了各亚专业组长会议，未来考虑组织各省质控中心的负责人召开会议。亚专业组培训视频以基础医疗为主，顾及到地区差异，应有统一质控标准。感谢大家参加本次会议，希望未来以高度的责任感，为国家做一些工作，圆满完成任务。
        </div>
        <div>
          <img src="@/assets/HY20240622/2.png" alt="">
        </div>
        <div class="div_tit">
          亚专业组组长发言及讨论
        </div>

      </section>
    </div>


  </div>
</template>

<script>
export default {
  name: "GDS2024"
}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 655px !important;
  box-sizing: border-box;
  height: auto !important;
  visibility: visible !important;
  margin: 10px 0;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}
</style>